<template>
	<div class="app-container">
		<el-header class="titles">积分商品管理</el-header>
		<el-card class="elCard">
			<div>
				<i class="el-icon-search"></i>
				<span>查询条件</span>
				<el-button style="float: right; margin-left: 5px;" @click="resetForm('searchForm')" size="small">清空</el-button>
				<el-button style="float: right" @click="searchTable()" type="primary" size="small" icon="el-icon-search">查询</el-button>
			</div>
			<div style="margin-top: 15px">
				<el-form :inline="true" ref="searchForm" :model="searchForm" size="small" label-width="100px">
					<el-form-item label="商品名字：" prop="name">
						<el-input v-model="searchForm.name" placeholder="请输入商品名字"></el-input>
					</el-form-item>
					<el-form-item label="条形码：" prop="code">
						<el-input v-model="searchForm.code" placeholder="请输入条形码"></el-input>
					</el-form-item>
					<el-form-item label="是否启用：" prop="status">
						<el-select v-model="searchForm.status" clearable placeholder="请选择" autocomplete="off" style="max-width: 200px;">
						    <el-option v-for="item in statusArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
		<el-row style="margin: 20px 0; text-align: right;">
			<el-button type="primary" icon="el-icon-refresh" @click='loadData("init")' size="mini">列表刷新</el-button>
			<el-button type="primary" @click='addOne' size="mini">新增商品</el-button>
		</el-row>
		<el-card>
		<el-table :data="tableData" border style="width: 100%;font-size: 12px;" tooltip-effect="dark"
		   v-loading="loading" max-height="500" @sort-change='sortChange'>
		   <el-table-column prop="createdStamp" label="创建时间" min-width="100" sortable='custom' :show-overflow-tooltip='true'>
		   </el-table-column>
			<el-table-column prop="name" label="商品名称" min-width="100" :show-overflow-tooltip='true'>
			</el-table-column>
			<el-table-column prop="price" label="商品价格" min-width="80" :show-overflow-tooltip='true'>
			</el-table-column>
			<el-table-column prop="integral" label="商品积分" min-width="80" :show-overflow-tooltip='true'>
			</el-table-column>
			<el-table-column prop="stock" label="商品库存" min-width="80" :show-overflow-tooltip='true'>
			</el-table-column>
			<el-table-column prop="picUrl" label="首页图片" width="130" >
				<template slot-scope="scope">
					<el-image style="width:80px; height:80px" :src="$comjs.imgPd(scope.row.picUrl)" fit="contain" 
					 :preview-src-list="srcList" @click='srcList = $comjs.resetArr($comjs.imgPd(scope.row.picUrl),srcList)'>
						<div slot="error" class="image-slot">
							<img style="width:80px; height:80px" src="../../../assets/logo.png" />
						</div>
					</el-image>
				</template>
			</el-table-column>
			<el-table-column prop="barCode" label="条形码" min-width="80" :show-overflow-tooltip='true'>
			</el-table-column>
			<el-table-column prop="description" label="备注" min-width="80" :show-overflow-tooltip='true'>
			</el-table-column>
			<el-table-column label="状态" width="100" prop="status">
				<template slot-scope="scope">
					<span>{{ options[scope.row.status==null?1:scope.row.status]}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="180" align='center' fixed="right">
				<template slot-scope="scope">
					<el-button @click="edit(scope.row.id)" type="warning" size="small">编辑</el-button>
					<el-button @click="toHandle(scope.row.id,1)" type="primary" size="small" v-if="scope.row.status === 0" >启用</el-button>
					<el-button @click="toHandle(scope.row.id,0)" type="danger" size="small" v-else>禁用</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination style="margin-top: 10px;" @size-change="((val)=>{$comjs.handleSizeChange(val,loadData)})"
		 @current-change="((val)=>{$comjs.handleCurrentChange(val,loadData)})" :current-page="$comjs.initPage.page"
		 :page-sizes="[5,10,20, 30, 50,100]" :page-size="$comjs.initPage.pageSize" layout="total,sizes, prev, pager, next,jumper"
		 :total="$comjs.total">
		</el-pagination>
		</el-card>
		<el-dialog :title="title" :visible.sync="dialogFormVisible" @close="clearImgArr" :destroy-on-close='true' :close-on-click-modal='false'  >
		    <el-form :model="detailData" size="mini" :rules="rules" ref="detailData" v-loading='dialogLoading' :label-width="$comjs.formLabelWidth">
			    <el-form-item label="商品名称:"  prop="name">
			    	<el-input v-model="detailData.name"  clearable></el-input>
			    </el-form-item>
				<el-form-item label="条形码:"  >
					<el-input v-model="detailData.barCode"  clearable></el-input>
				</el-form-item>
				<el-form-item label="价格:"  prop="price">
					<el-input type="number" v-model="detailData.price"  clearable></el-input>
				</el-form-item>
				<el-form-item label="积分:"  prop="integral">
					<el-input v-model.number="detailData.integral"  clearable></el-input>
				</el-form-item>
				<el-form-item label="热门排序:"  prop="sort">
					<el-input type="number" v-model="detailData.sort"  clearable></el-input>
				</el-form-item>
				<el-form-item label="库存:"  prop="stock">
					<el-input v-model.number="detailData.stock"  clearable></el-input>
				</el-form-item>
				<el-form-item label="主图:" >
					<upload-img :file-list='imgUrlList' @change='resetImgArr' ref='goodsHeader' @loadChange='changeFlag' :num='1'></upload-img>
				</el-form-item>
				<el-form-item label="详情图:" >
					<upload-img :file-list='detailImgUrlList' @change='resetImgArr2' ref='goodsHeader' @loadChange='changeFlag'></upload-img>
				</el-form-item>
				<el-form-item label="状态:" >
					<el-select v-model="detailData.status" placeholder="请选择" filterable>
						<el-option v-for="(item,index) in options" :label="item" :value="index">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="产品简介:" >
					<el-input v-model="detailData.description"   type="textarea"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button @click="dialogFormVisible = false" size="medium">取 消</el-button>
					<el-button type="primary" @click="submitForm('detailData')" size="medium">确定</el-button>
				</el-form-item>
			</el-form>
		 </el-dialog>
	</div>
</template>
<script>
	import uploadImg from '../../../components/uploadImg.vue';
	export default {
		created() {
			this.loadData('init');
		},
		components: {
			uploadImg
		},
		data() {
			return {
				title: '',//新增、编辑
				searchForm: {
					name: '',
					area: '',
					status: 1
				},//条件查询form
				statusArr: [{
					value: 0,
					label: "禁用"
				}, {
					value: 1,
					label: "启用"
				}],//是否启用
				dialogFormVisible:false,
				loading:false,
				dialogLoading:false,
				srcList:[],
				tableData:[],
				postData: [{
					col: 'name',
					type: 'like',
					val: ''
				}, {
					col: 'barCode',
					type: 'like',
					val: ''
				},{
					col: 'status',
					type: '=',
					val: ''
				}],
				rules:{
					price: [{
						required: true,
						message: '请输入价格'
					}],
					stock: [{
						required: true,
						message: '请输入库存',
						type:'number'
					}],
					name:[{
						required: true,
						message: '请输入商品名称'
					}]
				},
				options:['禁用中','启用中'],
				detailData:{
					
				},
				detailInitData:{
					goodsName:'',
					price:'',
					integral: '',
					stock: '',
					description:'',
					status:1
				},
				imgUrlList:[],
				detailImgUrlList:[],
			}
		},
		methods:{
			// 搜索
			searchTable() {
				this.page = 1;
				this.loadData('init');
			},
			// 重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.page = 1;
				this.loadData('init');
			},
			toHandle(id,status){
				// var url = ''
				// if(status == 1){
				// 	url = '/g/admin/teacherCenter/activeProduct'
				// }else{
				// 	url = '/g/admin/teacherCenter/disableProduct'
				// }
				this.$comjs.ajax.postAjax('/fa/exam/goods/enabled',{id},this,(res)=>{
					this.$message({
					  message:res.message,
					  type:'success'
					});
					this.loadData();
				})
			},
			clearImgArr(){
				this.dialogFormVisible = false
				this.imgUrlList = []
				this.detailImgUrlList = []
			},
			submitForm(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.detailData.picUrl = this.$comjs.integrationImg(this.imgUrlList);
						this.detailData.detailPicUrl = this.$comjs.integrationImg(this.detailImgUrlList);
						if(this.title == '编辑产品') {
							this.$comjs.ajax.postJsonAjax('/fa/exam/goods/edit',this.detailData,this,(res)=>{
								this.$message({
								  message:res.message,
								  type:'success'
								});
								this.loadData();
								this.dialogFormVisible = false;
							})
						} else {
							this.$comjs.ajax.postJsonAjax('/fa/exam/goods/add',this.detailData,this,(res)=>{
								this.$message({
								  message:res.message,
								  type:'success'
								});
								this.loadData();
								this.dialogFormVisible = false;
							})
						}
					} else {
						console.log(valid)
					}
				});
			},
			// 编辑
			edit(id){
				this.title = '编辑产品';
				var data = {
					id: id
				}
				this.$comjs.ajax.getAjax('/fa/exam/goods/query', data,this,(res) => {
					this.detailData = res.list[0];
					this.dialogFormVisible = true;
					if(res.list[0].picUrl){
						this.$comjs.imgArrOpe(res.list[0].picUrl,this.imgUrlList)
					}
					if(res.list[0].detailPicUrl){
						this.$comjs.imgArrOpe(res.list[0].detailPicUrl,this.detailImgUrlList)
					}
				})
			},
			// 新增商品
			addOne(){
				this.title = '新增产品';
				this.detailData = Object.assign({},this.detailInitData);
				this.dialogFormVisible = true;
			},
			// 上传图片显示等待中
			changeFlag(value) {
				this.dialogLoading = value
			},
			resetImgArr(list) {
				this.imgUrlList = list
			},
			resetImgArr2(list){
				this.detailImgUrlList = list
			},
			loadData(init) {
				if (init) {
					this.$comjs.initPage.page = 1;
				}
				this.loading = true;
				// var data = Object.assign({}, this.$comjs.initPage);
				// data.entity = 'PdSpecialSampleProduct';
				// data.filter = JSON.stringify(this.postData);
				let data = {
					pageNumber: this.$comjs.initPage.page,
					pageSize: this.$comjs.initPage.pageSize,
					name: this.searchForm.name,
					barCode: this.searchForm.code,
					status: this.searchForm.status
				}
				this.$comjs.ajax.getAjax('/fa/exam/goods/query', data,this,(res) => {
					this.$comjs.total = res.total;
					this.tableData = res.list;
					this.loading = false;
					for (var i in res.list) {
						var imgUrl = this.$comjs.imgPd(res.list[i].picUrl)
						this.srcList.push(imgUrl)
					}
				})
			},
			// 排序函数
			sortChange(obj) {
				console.log(obj)
				this.$comjs.initPage.sidx = obj.prop;
				if (obj.order) {
					if (obj.order == 'ascending') {
						this.$comjs.initPage.sord = 'asc'
					} else {
						this.$comjs.initPage.sord = 'desc'
					}
				} else {
					this.sord = ''
				}
				this.loadData()
				// if(obj.)
			}
		}
	}
</script>
<style scoped="scoped">
	.app-container {
		padding: 20px;
	}
	.titles {
		border: 1px solid #E4E7ED;
		border-radius: 4px;
		line-height: 60px;
	}
	.elCard {
		margin-top: 20px;
	}
</style>